import React, { useState } from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { AnimatePresence, motion } from "framer-motion"

const Section = styled.section`
  ${tw`relative`}
`
const Headline = styled.h2`
  ${tw`relative font-bold text-black font-ubuntu text-42 md:text-40 lg:text-48 2xl:text-60 pb-c40 px-offset sm:px-12 lg:px-16 xl:px-24`}
`
const Teasers = styled.div`
  ${tw`relative grid gap-6 px-offset sm:px-12 lg:px-16 xl:px-24 2xl:px-40 sm:grid-cols-2 xl:gap-10`}
`
const Column = styled.div`
  ${tw`relative`}
`
const TeaserBox = styled(motion.div)`
  ${tw`relative overflow-hidden cursor-pointer pt-c25 pb-c25`}
`
const TeaserTitle = styled(motion.p)`
  ${tw`relative w-4/5 font-bold font-ubuntu text-blue text-20 md:text-24 xl:text-30 pb-c15`}
`
const TeaserDescriptionWrap = styled(motion.div)`
  ${tw`relative `}
`
const TeaserDescription = styled(motion.p)`
  ${tw`relative pr-6 font-normal transition-all duration-500 ease-in-out font-firacode text-14 md:text-16 sm:pr-0 md:w-2/3`}
`
const Arrow = styled(motion.span)`
  ${tw`absolute right-0 block font-bold transform -bottom-4 sm:-bottom-2 md:bottom-2 md:right-20 xl:right-40 font-firacode text-24 text-blue`}
`

const WpAcfTextTeasersModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfTextTeasersBlock
  const sectionAttributes = moduleData.attributes
  const sectionId = sectionAttributes.anchor || ""

  const [expanded, setExpanded] = useState([])
  const [hovered, setHovered] = useState([])

  const firstColumn = blockData.items.filter((item, index) => index % 2 === 0)
  const secondColumn = blockData.items.filter((item, index) => index % 2 !== 0)

  return (
    <Section
      id={sectionId}
      className={`${
        blockData.topSpacing ? "pt-0" : "pt-c120 md:pt-c96 2xl:pt-c120"
      } ${blockData.bottomSpacing ? "pb-0" : "pb-c120 md:pb-c96 2xl:pb-c120"}`}
    >
      <Headline>{blockData.headline}</Headline>
      <Teasers>
        <Column>
          {firstColumn.map((item, index) => {
            return (
              <TeaserItem
                key={`col1-${index}`}
                index={`col1-${index}`}
                trimWords={item.trimWords || 15}
                title={item.title}
                description={item.description}
                expanded={expanded}
                setExpanded={setExpanded}
                hovered={hovered}
                setHovered={setHovered}
              />
            )
          })}
        </Column>
        <Column>
          {secondColumn.map((item, index) => {
            return (
              <TeaserItem
                key={`col2-${index}`}
                index={`col2-${index}`}
                trimWords={item.trimWords || 15}
                title={item.title}
                description={item.description}
                expanded={expanded}
                setExpanded={setExpanded}
                hovered={hovered}
                setHovered={setHovered}
              />
            )
          })}
        </Column>
      </Teasers>
    </Section>
  )
}

const TeaserItem = ({
  index,
  title,
  description,
  expanded,
  setExpanded,
  trimWords,
  hovered,
  setHovered,
}) => {
  const transition = {
    duration: 0.8,
    ease: [0.04, 0.62, 0.23, 0.98],
  }
  const arrowVariants = {
    open: {
      rotate: -90,
      transition: transition,
    },
    init: {
      rotate: -270,
      transition: transition,
    },
    hover: {
      y: ["2px", "-2px"],
      transition: {
        repeat: Infinity,
        repeatType: "reverse",
        duration: 0.3,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  }
  const teaserVariants = {
    open: {
      display: "inline",
      visibility: "visible",
      opacity: 1,
      height: "auto",
      transition: transition,
    },
    init: {
      display: "block",
      visibility: "hidden",
      opacity: 0,
      height: 0,
      transition: {
        duration: 0,
      },
    },
  }

  const truncate = (string, words) => {
    return string.split(" ").splice(0, words).join(" ")
  }
  const truncateRest = (string, words) => {
    return " " + string.split(" ").splice(words, string.length).join(" ")
  }
  return (
    <TeaserBox
      initial={false}
      whileHover="hover"
      onHoverStart={() =>
        setHovered(currentHovered => {
          return [...currentHovered, index]
        })
      }
      onHoverEnd={() =>
        setHovered(currentHovered => {
          return currentHovered.filter(item => item !== index)
        })
      }
      onClick={() => {
        setExpanded(currentExpanded => {
          return expanded.includes(index)
            ? currentExpanded.filter(item => item !== index)
            : [...currentExpanded, index]
        })
      }}
      key={`textteaser-${index}`}
    >
      <TeaserTitle>{title}</TeaserTitle>
      <AnimatePresence initial={false}>
        <TeaserDescriptionWrap>
          <TeaserDescription>
            {truncate(description, trimWords)}
            {!expanded.includes(index) && <span>...</span>}
            <motion.span
              variants={teaserVariants}
              initial="init"
              animate={expanded.includes(index) ? "open" : "init"}
              exit="init"
            >
              {truncateRest(description, trimWords)}
            </motion.span>
          </TeaserDescription>
          <Arrow
            className="arrow"
            initial="init"
            animate={
              expanded.includes(index)
                ? "open"
                : hovered.includes(index) && !expanded.includes(index)
                ? "hover"
                : "init"
            }
            exit="init"
            variants={arrowVariants}
          >{`->`}</Arrow>
        </TeaserDescriptionWrap>
      </AnimatePresence>
    </TeaserBox>
  )
}

WpAcfTextTeasersModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

export default WpAcfTextTeasersModuleBlock
